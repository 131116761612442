import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { useCubeQuery } from '@cubejs-client/react';
import { Spin, Row, Col, Statistic, Table, Calendar, DatePicker, Space, Empty  } from 'antd';
import {
  CartesianGrid,
  PieChart,
	RadarChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
	RadialBarChart,
	RadialBar,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	Radar,
	Funnel
} from 'recharts';
import Plot from 'react-plotly.js';

function onPanelChange(value, mode) {
  console.log(value, mode);
}


const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.chartPivot()}>
      <XAxis dataKey="x" />
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip />
    </ChartComponent>
  </ResponsiveContainer>
);

const colors = [
	'#FF6492', '#141446', '#7A77FF', '#FF6492', '#E32DDD', '#E8361E',
	'#1c65ef', '#9e8df4', '#77910c', '#efb891', '#4816d6', '#1b1fce',

];

const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ', '),
        color: resultSet.axisValuesString(yValues, ', '),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};

const TypeToChartComponent = {
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet }) => (
		<div>
    	<CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
      	{resultSet.seriesNames().map((series, i) => (
        	<Bar
          	key={series.key}
          	stackId="a"
          	dataKey={series.key}
          	name={series.title}
          	fill={colors[i]}
        	/>
      	))}
    	</CartesianChart>
		</div>
  ),
	plotlyfunnel: ({resultSet})=>{
		//console.log("burst");
		const key = resultSet.seriesNames()[0] != undefined ? resultSet.seriesNames()[0].key : "";
		//console.log(resultSet.chartPivot(),resultSet.seriesNames());
		const keys = resultSet.chartPivot().map((yu)=>{
			return {"key": yu.key, "name": yu.title};
		})

		const est = resultSet.chartPivot();

		let values0 = est.map((xo)=>{
			if(xo[key] != null && xo[key] != undefined){
				return xo[key];
			}
		}).filter((io)=>{
			return io != undefined && io != null
		});

		if(values0.length > 0){
			let total = values0.reduce((a,b)=>{
				return a+b
			});
			values0.unshift(total)
		}
		let labels0 = est.map((xy)=>{
			if(xy[key] != null && xy[key] != undefined){
				return xy['category']
			}
		}).filter((io)=>{
			return io != undefined && io != null
		})

		let splitKey = key.split(".").join(" ");
		labels0.unshift(splitKey);

		let parents0 = ["",splitKey].concat(labels0);
		parents0.push(splitKey);

		//const data0 = resultSet.chartPivot().map((u,i)=>{
		//	return u.x;
		//});
		let colors0 = [];

		labels0.forEach((y,i)=>{
			colors0.push(colors[i % colors.length])
		})

		let dator = [{
			type:"funnel",
			y: labels0,
			x: values0,
			textinfo: "value+percent initial",
			opacity: 0.65,
			marker: {
				color: colors0,
				line: {
					"width": [4, 2, 2, 3, 1, 1],
					color: colors0
				}
			}
		}];

		return (
				<Plot
				data={dator}
				layout={{
					autosize: true,
					margin: {"l": 0, "r": 0, "b": 0, "t": 0}
				}}
				responsive={true}
				style={{width: "100%", height: "350px"}}
				//useResizeHandler={true}
				/>
		)
	},
	plotlyline: ({ resultSet }) => {
		//console.log("--------------------line---------------------------");
		const key = resultSet.seriesNames()[0].key;
		//console.log(resultSet.seriesNames());
		const keys = resultSet.seriesNames().map((yu)=>{
			return {"key": yu.key, "name": yu.title};
		})
		const est = resultSet.chartPivot();
		//console.log(est);
		const data0 = est.map((u,i)=>{
			return u.category;
		});
		let dator = [];
		//console.log(keys);
		for (var i = 0; i < keys.length; i++) {
			const data = unpack(est, keys[i].key);
			//console.log(data, data0);
			dator.push({
				type: 'scatter',  mode:'lines', x: data0, y: data, name: keys[i].name
			})
		}

		return (
				<Plot
				data={dator}
				layout={{
					margin: {"l": 0, "r": 0, "b": 0, "t": 0},
					autosize: true,
					showlegend:true,
					legend:{
						orientation: "h"
					}
				}}
				style={{
					width: "100%",
					height: "350px"
				}}
				responsive={true}
				useResizeHandler={true}
				/>
		)
	},
	plotlysunburst: ({ resultSet }) => {
		//console.log("burst");
		const key = resultSet.seriesNames()[0].key;
		//console.log(resultSet.chartPivot(),resultSet.seriesNames());
		const keys = resultSet.chartPivot().map((yu)=>{
			return {"key": yu.key, "name": yu.title};
		})

		const est = resultSet.chartPivot();

		let values0 = est.map((xo)=>{
			if(xo[key] != null && xo[key] != undefined){
				return xo[key];
			}
		}).filter((io)=>{
			return io != undefined && io != null
		});

		if(values0.length > 0){
			let total = values0.reduce((a,b)=>{
				return a+b
			});
			values0.unshift(total)
		}
		let labels0 = est.map((xy)=>{
			if(xy[key] != null && xy[key] != undefined){
				return xy['category']
			}
		}).filter((io)=>{
			return io != undefined && io != null
		})

		let splitKey = key.split(".").join(" ");
		labels0.unshift(splitKey);

		let parents0 = ["",splitKey].concat(labels0);
		parents0.push(splitKey);

		//const data0 = resultSet.chartPivot().map((u,i)=>{
		//	return u.x;
		//});
		let dator = [{
			type:"sunburst",
			labels: labels0,
			parents: parents0,
			values: values0,
			outsidetextfont: {size: 20, color: "#377eb8"},
			leaf: {opacity: 0.4},
			marker: {line: {width: 2}}
		}];
		//for (var i = 0; i < est.length; i++) {
		//	labels.push(keys[i].name);
		//	parents.push(keys[i].name);
		//	let data0 = unpack(est, keys[i].key);
		//	console.log(data0);
		//	dator.push(data0);
		//}

		//console.log(values0, parents0, labels0);
		//const x0 = data0.map((x)=>{
		//	return x.x
		//});

		//const y0 = data0.map((y)=>{
		//	return y.y
		//})

		return (
				<Plot
				data={dator}
				layout={{
					autosize: true,
					margin: {"l": 0, "r": 0, "b": 0, "t": 0}
				}}
				responsive={true}
				style={{width: "100%", height: "350px"}}
				//useResizeHandler={true}
				/>
		)
	},
  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => {
		const srs = resultSet.seriesNames();
		if(srs.length > 0){
			return(
    		<ResponsiveContainer width="100%" height={350}>
      		<PieChart>
        		<Pie
          		isAnimationActive={false}
          		data={resultSet.chartPivot()}
          		nameKey="x"
          		dataKey={srs[0].key}
          		fill="#8884d8"
        		>
          		{resultSet.chartPivot().map((e, index) => (
            		<Cell key={index} fill={colors[index % colors.length]} />
          		))}
        		</Pie>
        		<Legend />
        		<Tooltip />
      		</PieChart>
    		</ResponsiveContainer>
			)
		}
		else{
			return(
				<ResponsiveContainer width="100%" height={350}>
					<Row>
						<Col span={24}>
							<Empty
								style={{'marginTop': '2em'}}
							/>
						</Col>
					</Row>
				</ResponsiveContainer>
			)
		}
  },
  number: ({ resultSet }) => (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{
        height: '100%',
      }}
    >
      <Col>
        {resultSet.seriesNames().map((s) => (
          <Statistic value={resultSet.totalRow()[s.key]} />
        ))}
      </Col>
    </Row>
  ),
  table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={true}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={resultSet.tablePivot(pivotConfig)}
    />
  ),
	radial: ({resultSet})=>{
		const srs = resultSet.seriesNames();
		console.log(srs, resultSet.tablePivot());

		const data = resultSet.chartPivot().map((e, index)=>{
			let obj = {
				"name": e.category,
				"fill": colors[index % colors.length]
			};
			obj[srs[0].key] = e[srs[0].key];
			return obj;
		});

		return (
			<RadialBarChart
			  width={730}
			  height={250}
			  innerRadius="10%"
			  outerRadius="80%"
			  data={data}
			  startAngle={180}
			  endAngle={0}
			>
			  <RadialBar minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey={srs[0].key} />
			  <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
			  <Tooltip />
			</RadialBarChart>
		)
	}
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => ({ resultSet, error, pivotConfig }) =>
  (resultSet && (
    <Component resultSet={resultSet} pivotConfig={pivotConfig} />
  )) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState }) => {
  const { query, chartType, pivotConfig } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query,{
    subscribe: true,
  });
  return component && renderChart(component)({ ...renderProps, pivotConfig });
};

const unpack = function(rows, key) {
	return rows.map(function(row) { return row[key]; });
}
ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
