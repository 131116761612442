import React, {useState} from 'react';
import { Card, Menu, Button, Dropdown, Modal, Calendar, DatePicker, Space, Col, Row, Tooltip } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { useQuery  } from '@apollo/react-hooks';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
import { UndoOutlined } from '@ant-design/icons';
import {cubejsApi} from '../App.js'
import { GET_DASHBOARD_ITEMS, GET_DASHBOARD_ITEM } from '../graphql/queries';
import { DELETE_DASHBOARD_ITEM, UPDATE_DASHBOARD_ITEM } from '../graphql/mutations';

const { RangePicker } = DatePicker;
const getDashboardItems = () =>{
  let items = JSON.parse(window.localStorage.getItem('dashboardItems'));
	return items;
}

const getRange = (itemId, daterange) =>{

	const dashboardItems = getDashboardItems();
	let item = dashboardItems.find((el)=>{
		return el.id == itemId;
	});

	let idx = dashboardItems.findIndex((el)=>{
		return el.id == itemId;
	});

	let startDate = moment(daterange[0]).format('YYYY-MM-DD');
	let endDate = moment(daterange[1]).format('YYYY-MM-DD');
	let vz = JSON.parse(item.vizState);

	vz['query']['timeDimensions'][0]['dateRange'] = [startDate, endDate];

	dashboardItems[idx] = vz;

	//localStorage.setItem('dashboardItems', JSON.stringify(dashboardItems));
	return JSON.stringify(vz);
	//vz.query.timeDimensions
}

const resetRange = (itemId) =>{
	const dashboardItems = getDashboardItems();
	let item = dashboardItems.find((el)=>{
		return el.id == itemId;
	});

	let idx = dashboardItems.findIndex((el)=>{
		return el.id == itemId;
	});

	let vz = JSON.parse(item.vizState);
	vz = JSON.parse(item['defaultview']);
	dashboardItems[idx] = vz;

	//localStorage.setItem('dashboardItems', JSON.stringify(dashboardItems));
	return JSON.stringify(vz);
}

const DashboardItemDropdown = ({ itemId }) => {
  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS,
      },
    ],
  });

  const dashboardItemDropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link to={`/explore?itemId=${itemId}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',

            onOk() {
              removeDashboardItem({
                variables: {
                  id: itemId,
                },
              });
            },
          })
        }
      >
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={dashboardItemDropdownMenu}
      placement="bottomLeft"
      trigger={['click']}
    >
      <Button shape="circle" icon={<Icon type="menu" />} />
    </Dropdown>
  );
};

const DashboardItem = ({ itemId, children, title }) => {

	const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
		refetchQueries: [
			{
				query: GET_DASHBOARD_ITEMS,
			},
		]
	});

	const getDates = () => {
		const dashboardItems = getDashboardItems();
		let idx = dashboardItems.findIndex((el)=>{
			return el.id == itemId;
		});

		let vz = JSON.parse(dashboardItems[idx].vizState);
		let times = vz['query']['timeDimensions'][0]['dateRange'];
		if(times != undefined && times.length == 2){
			return times;
		}
	}

	const renderDateRange = () => {
		const dashboardItems = getDashboardItems();
		let idx = dashboardItems.findIndex((el)=>{
			return el.id == itemId;
		});

		let check = dashboardItems[idx]['showDaterange'];
		if(check === 'true'){
			return (
				<Col span={24}>
					<Row>
						<Col span={24}>
							<RangePicker
								placeholder={getDates()}
								onCalendarChange={val => {
									if(val != null && !val.includes(null)){
										updateDashboardItem({
											variables: {
												id: itemId,
												input: {
													vizState:getRange(itemId, val)
												},
											},
										});
									}
								}}
							/>
							<Button
								icon={<UndoOutlined/>}
								title="Reset"
								onClick={()=>{
									updateDashboardItem({
										variables: {
											id: itemId,
											input: {
												vizState:resetRange(itemId)
											},
										},
									});
								}}
							/>
						</Col>
					</Row>
				</Col>
			)
		}
		else{
			return (
				<>
				</>
			)
		}

	}




	return(
  	<Card
    	title={title}
    	style={{
      	height: '100%',
      	width: '100%',
    	}}
    	extra={<DashboardItemDropdown itemId={itemId} />}
  	>
			<Row>
				{renderDateRange()}
				<Col span={24}>
    			{children}
				</Col>
			</Row>
  	</Card>
	)
};

export default DashboardItem;
