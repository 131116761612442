import React from 'react';
import './main.css';

const PistePilvi = () => {
    return(
    <iframe src="http://majakka.westeurope.cloudapp.azure.com/mjkpp/index.html" width='100%' height='100%'></iframe>
    );
};

export default PistePilvi;
