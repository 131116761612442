import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect,HashRouter as Router, Route } from 'react-router-dom';
import ExplorePage from './pages/ExplorePage';
import DashboardPage from './pages/DashboardPage';
import PotreePage from './pages/PotreePage';
import PistePilvi from './pages/PistePilvi';
import HomePage from './pages/HomePage';
import App from './App';
import './pages/main.css';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import LoginPage from './pages/LoginPage';
import Forge2LegPage from './pages/Forge2LegPage';
import ForgeBimPage from './pages/ForgeBimPage';
import ForgePage from './pages/ForgePage';
import DemoPage from './pages/DemoPage';
import { getToken } from './Utils/Common';


ReactDOM.render(
  <Router>
    <App>
      <PrivateRoute key="dashboard" exact path="/dashboard" component={DashboardPage} />
      <PrivateRoute key="explore" path="/explore" component={ExplorePage} />
      <PrivateRoute key="potree" path="/potree" component={PotreePage} />
      <PrivateRoute key="pistepilvi" path="/pistepilvi" component={PistePilvi} /> 
      <PrivateRoute key="digitaltwin" path="/digitaltwin" component={"null"}/>
      <PrivateRoute key="home" path="/home" component={HomePage} />
			<PrivateRoute key="forge" path="/forge" component={ForgePage} />
      <PrivateRoute key="forge2leg" path="/forge2leg" component={Forge2LegPage} />
      <PrivateRoute key="forgebim" path="/forgebim" component={ForgeBimPage} />
			<PrivateRoute key="demos" path="/demos" component={"null"} />
			<PrivateRoute key="wiki" path="/wiki" component={"null"} />
			<PrivateRoute key="demo0" path="/demo0" component={DemoPage} />
      <PublicRoute key="login" path="/login" component={LoginPage} />
			<Route exact path="/" render={() => (
  			!getToken() ? (
    			<Redirect to="/dashboard"/>
  			) : (
    			<Redirect to="/login"/>
  			)
			)}/>
    </App>
  </Router>, // eslint-disable-next-line no-undef
  document.getElementById('container')
);
