import React from 'react';
import {Carousel, Layout, Typography, Divider} from 'antd';
import './main.css';
import LogoVipu from './VipuvoimaaEU.png';
import LogoKP from './KPLiitto.jpg';
import LogoPP from './RGB_v_700px.jpg';
import LogoEAKR from './EU_EAKR_FI.png';
import Majakka_logo from '../Majakka_logo.svg';
const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const contentStyle = {
    color: '#fff',
    textAlign: 'center',
    background: '#001529',
    width: 'auto',
    margin: 'auto'
  };

const HomePage = () =>{
    return(
        <Layout>
					<Content>
						<div style={{textAlign: 'center', paddingTop:'10px'}}>
							<Typography>
								<Title>MAJAKKA - toolbox for low carbon industry</Title>
								<div style={{ textAlign:'center'}}>
										<img src={Majakka_logo} height='256px' width='196px'></img>
								</div>
								<Paragraph>
										Centria University of Applied Sciences&apos; and Oulu University&apos;s crosscounty Majakka-project, which enables the digitalization of local industry <br />

										Project aims to cross-section industrial processes in order to present the benefits of low carbon industry <br />

										The main funders are Central Ostrobothnia&apos;s cooperative regions and Council of Oulu region with munincipal funding from KOSEK and BusinessOulu and support from 12 companies
								</Paragraph>
							</Typography>
						</div>
						<Footer style={{padding: '0px', margin:'0px', paddingTop:'10px'}}>
              	<h3 style={{textAlign: 'center', height: '25px', color:'#001529', marginBottom: '0px'}}>Majakka Project is funded by:</h3>
          			<Carousel
          				autoplay="true"
          				className="carousel"
          				dotPosition="bottom"
          			>
              		<div>
                  	<a href="http://www.keski-pohjanmaa.fi/" target="_blank" rel="noopener noreferrer">
                    	<img src={LogoKP} alt="Keski-Pohjanmaan Liitto" style={{width: '50%', margin:'auto'}} />
                  	</a>
              		</div>
              		<div>
                  	<a href="https://www.pohjois-pohjanmaa.fi/" target="_blank" rel="noopener noreferrer">
                    	<img src={LogoPP} alt="Pohjois-Pohjanmaan Liitto" style={{width: '50%', margin:'auto'}} />
                  	</a>
              		</div>
              		<div>
              			<a href="http://www.keski-pohjanmaa.fi/" target="_blank" rel="noopener noreferrer">
                    	<img src={LogoVipu} alt="Vipu Voimaa EU:lta" style={{width: '120px', margin:'auto', backgroundColor:'#fff'}}/>
                  	</a>
              		</div>
              		<div style={{}}>
              			<a href="http://www.keski-pohjanmaa.fi/" target="_blank" rel="noopener noreferrer">
                    	<img src={LogoEAKR} alt="Euroopan Unionin Aluekehitysrahasto" style={{width: '100px', margin:'auto', backgroundColor:'#fff'}} />
                  	</a>
              		</div>
          			</Carousel>
							</Footer>
					</Content>
        </Layout>
    );
};

export default HomePage
