import React, { useState } from 'react';
import { Alert, Button, Spin, Switch } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import './main.css';
import ExploreQueryBuilder from '../components/QueryBuilder/ExploreQueryBuilder';
import { GET_DASHBOARD_ITEM } from '../graphql/queries';
import TitleModal from '../components/TitleModal.js';
const ExplorePage = withRouter(({ history, location }) => {
  const [addingToDashboard, setAddingToDashboard] = useState(false);
  const params = new URLSearchParams(location.search);
  const itemId = params.get('itemId');
  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEM, {
    variables: {
      id: itemId,
    },
    skip: !itemId,
  });
  const [vizState, setVizState] = useState(null);
	const [rangeEnabled, enableRange] = useState(null);
	const rng = (itemId && !loading && data && (data.dashboardItem.showDaterange)) || 'false';

	const checkRng = () =>{
		if(rng == 'true'){
			return true;
		}
		else{
			return false;
		}
	}

  const finalVizState =
    vizState ||
    (itemId && !loading && data && (JSON.parse(data.dashboardItem.defaultview) || JSON.parse(data.dashboardItem.vizState))) || {};

  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const finalTitle =
    title != null
      ? title
      : (itemId && !loading && data && data.dashboardItem.name) || 'New Chart';

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert type="error" message={error.toString()} />;
  }

  return (
    <div>
      <TitleModal
        history={history}
        itemId={itemId}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
        setAddingToDashboard={setAddingToDashboard}
        finalVizState={finalVizState}
				defaultview={JSON.stringify(finalVizState)}
        setTitle={setTitle}
        finalTitle={finalTitle}
				rangeStatus={rangeEnabled}
      />
      <ExploreQueryBuilder
        vizState={finalVizState}
        setVizState={setVizState}
        chartExtra={[
					<Switch
						checkedChildren="Daterange on"
						unCheckedChildren="Daterange off"
						onChange={(val) => enableRange(val)}
						defaultChecked={checkRng()}
						style={{'marginRight':'1em'}}
					/>,
          <Button
            key="button"
						shape="round"
            type="primary"
            loading={addingToDashboard}
            onClick={() => setTitleModalVisible(true)}
          >
            {itemId ? 'Update' : 'Add to Dashboard'}
          </Button>
        ]}
      />
    </div>
  );
});
export default ExplorePage;
