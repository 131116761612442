export const getUser = () =>{
    const userStr = sessionStorage.getItem('username');
    if(userStr) return JSON.parse(userStr);
    else return null;
}
export const getToken = () =>{
    return sessionStorage.getItem('token') || null;
}
export const removeUserSession = () =>{
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('organization');
    sessionStorage.removeItem('role');
		localStorage.removeItem('dashboardItems');
		localStorage.removeItem('dashboardIdCounter');

}
export const setUserSession = (token, username,organization,role,email,firstName,lastName, dashboardItems, dashboardCounter) =>{
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('username', JSON.stringify(username));
    sessionStorage.setItem('organization', JSON.stringify(organization));
    sessionStorage.setItem('role', JSON.stringify(role));
    sessionStorage.setItem('email', JSON.stringify(email));
    sessionStorage.setItem('firstName', JSON.stringify(firstName));
    sessionStorage.setItem('lastName', JSON.stringify(lastName));
		localStorage.setItem('dashboardItems', dashboardItems);
		localStorage.setItem('dashboardIdCounter', dashboardCounter);
}

export const checkEnv = () =>{
	return process.env.NODE_ENV;
}
