import './body.css';
import 'antd/dist/antd.css';
import React from 'react';
import '@ant-design/compatible';
import { ApolloProvider } from '@apollo/react-hooks';
import { Layout, Menu } from 'antd';
import cubejs from '@cubejs-client/core';
import { CubeProvider, useCubeQuery } from '@cubejs-client/react';
import client from './graphql/client';
import Header from './components/Header';
import WebSocketTransport from '@cubejs-client/ws-transport';
import SiderComponent from './components/Sider';
import './pages/main.css';
import {checkEnv} from './Utils/Common.js';


let dEnv = checkEnv();
let ws_uri = null;

if(dEnv == 'production'){
	ws_uri = 'ws://majakka.westeurope.cloudapp.azure.com/cubejs/';
}
else{
	ws_uri = 'ws://localhost:4000/';
}

const CUBEJS_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MDI3NDYzMjgsImV4cCI6MTYwMjgzMjcyOH0.3ZNM3srcpje3HS5tuXzzz3VMFbEBYs9igAmPa_1xKOc';
const cubejsApi = cubejs({
  transport: new WebSocketTransport({
		authorization: CUBEJS_TOKEN,
		apiUrl: ws_uri
	}) //ws://majakka.westeurope.cloudapp.azure.com/cubejs/
});

const setTitle = () =>{
	document.title = 'Majakka';
}

const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: '100vh',
    }}
  >
    <Header />
    <Layout style={{
      height: '100vh',
    }}>


    <SiderComponent />





    <Layout.Content>{children}</Layout.Content>
    </Layout>

  </Layout>



);

const App = ({ children }) => (
	<>
		{setTitle()}
  	<CubeProvider cubejsApi={cubejsApi}>
    	<ApolloProvider client={client}>
      	<AppLayout>{children}</AppLayout>
    	</ApolloProvider>
  	</CubeProvider>
	</>
);

export default App;
export {cubejsApi};
