import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../Majakka_logo.svg";
import { withRouter,Redirect } from 'react-router';
import { Layout, Menu, Dropdown } from 'antd';
import './Header.css';
import {RightCircleFilled, UserOutlined, DownOutlined} from "@ant-design/icons";
import SubMenu from 'antd/lib/menu/SubMenu';
import {getUser, removeUserSession} from '../Utils/Common';
import ApolloClient from '../graphql/client.js'

const user = getUser();

const handleLogout = () =>{
  removeUserSession();
	ApolloClient.cache.reset();
}

const Header = ({ location }) => (
  <Layout.Header
    style={{
      padding: '0 32px',


    }}
  >
    <div
      style={{
        float: 'left',
      }}
    >
      <a href="/#/home">
      <img className="colorize" src={Logo} height='64px' width='48px' style={{background: 'white'}}></img>
      </a>
    </div>
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[location.pathname]}
      style={{
        lineHeight: '64px',
      }}
    >
      <Menu.Item key="/dashboard">
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="/digitaltwin">
        <Link to="/digitaltwin">Digital Twin</Link>
      </Menu.Item>
			{/*}
			<Menu.Item key="/demos">
				<Link to="/demos">Demos</Link>
			</Menu.Item>
			*/}
			<Menu.Item key="/wiki">
				<a href="http://majakka.westeurope.cloudapp.azure.com:3064/fi/home" target="_blank" rel="noopener noreferrer">
					Wiki
				</a>
			</Menu.Item>


        <SubMenu key="/profilemenu" icon={<UserOutlined />} style={{float: 'right'}} title="Profile">
          <Menu.Item key="/profile"> Profile
            <Link to="/profile" />
          </Menu.Item>
        <Menu.Item key="logout" onClick={handleLogout}> Logout
          <Link to="/login" />
          </Menu.Item>
        </SubMenu>






    </Menu>
  </Layout.Header>

);

export default withRouter(Header);
