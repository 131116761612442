import axios from 'axios';
import {checkEnv} from './Common.js';

export function saveDashboard(){
	const localStorage = window.localStorage;
	const session = window.sessionStorage;
	const data = {
		'username': session.username.replaceAll('"', ''),
		'email': session.email.replaceAll('"', ''),
		'items': localStorage.dashboardItems,
		'counter': localStorage.dashboardIdCounter
	};

	let dEnv = checkEnv();
	let uri = null;

	if(dEnv == 'production'){
		uri = '/dbapi/api/updatedashboard';
	}
	else{
		uri = 'http://localhost:9191/api/updatedashboard';
	}

	axios.put(uri, data).then(function(items){
		//console.log(items);
	});
}
