import React, {useState} from 'react';
import axios from 'axios';
import {setUserSession} from '../Utils/Common';
import Majakka_logo from '../Majakka_logo.svg';
function Login(props){
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);

    const handleKeyPress = (event) =>{
        if(event.key === "Enter")
            handleLogin();
    }
    const handleLogin = () =>{
        setError(null);
        setLoading(true);
//	const instance = axios.create({
//	baseURL:'http://majakka.westeurope.cloudapp.azure.com',
//	withCredentials:true,
//	method: 'post',
//	headers: {'Content-Type':'application/json'}
//	});
//	instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//	instance.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
        axios.post('/api/users/authenticate', {username: username.value, password: password.value}).then(response =>{
            setLoading(false);
            setUserSession(response.data.token, response.data.username,response.data.organization, response.data.role,response.data.email, response.data.firstName,response.data.lastName, response.data.dashboardItems, response.data.dashboardIdCounter);
            props.history.push('/home');
        }).catch(error =>{
            setLoading(false);
            if(error.response.status === 401) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        });
    }
    return(
        <div style={{ textAlign:'center'}}>
            <img src={Majakka_logo} height='256px' width='196px'></img>
        <div>
      Login<br /><br />
      <div>
        Username<br />
        <input type="text" {...username} autoComplete="new-password" />
      </div>
      <div style={{ marginTop: 10 }}>
        Password<br />
        <input type="password" {...password} autoComplete="new-password" />
      </div>
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} onKeyUp={handleKeyPress}/><br />
    </div>
    </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;
