import React from 'react';
import { Descriptions, Radio, Button, Divider, Carousel  } from 'antd';
const contentStyle = {

  height: '20vh',
	width: '40vw',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const DemoDisplay = () =>{
    return(
			<div >
				<Descriptions
					bordered
					title="Demo #1"
					style={{padding:"24px"}}
					size="small"
					column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
				>
					<Descriptions.Item label="Description">
						<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vulputate vestibulum enim, at facilisis diam aliquet quis. Cras maximus dui euismod, mattis ex sed, fermentum magna. Fusce ex orci, ultricies id sagittis in, feugiat at purus. Fusce eu imperdiet lacus. Ut malesuada ligula id nisl finibus, a aliquam erat imperdiet. Aenean vulputate nibh neque, quis posuere turpis sollicitudin facilisis. Nulla purus purus, congue ut nunc sed, convallis hendrerit urna. Morbi dictum quam sed quam venenatis auctor. Praesent at augue elementum, pellentesque augue sed, auctor arcu. Sed rhoncus ut turpis et gravida. Integer consequat aliquet justo eget ullamcorper. Donec at augue ullamcorper, elementum massa a, euismod arcu.

						Phasellus facilisis porta tortor in sagittis. Integer sit amet eros libero. Nulla facilisis volutpat pulvinar. Integer et massa eros. Nulla a auctor mauris. Sed eu venenatis urna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis accumsan et risus ac eleifend. Cras dui mauris, consectetur sed auctor vel, gravida sed felis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam porta ligula viverra ligula ornare, a mollis neque pharetra.

						Vestibulum at turpis lectus. Vestibulum eget venenatis diam. Proin maximus nulla nisi, sit amet vehicula felis bibendum nec. Nam vel sem ante. Etiam scelerisque facilisis neque nec rutrum. Vestibulum vulputate dui neque, ac interdum orci viverra a. Donec pretium lacus ut metus tincidunt finibus. Morbi pretium orci vel tempus venenatis. Vestibulum elit diam, semper at consectetur maximus, feugiat vitae dui. Curabitur luctus elit velit, at faucibus sem cursus nec. Donec maximus, lorem id auctor auctor, metus augue convallis felis, sit amet gravida ex lorem efficitur nibh. Phasellus blandit, urna id placerat rhoncus, ligula sapien varius nulla, sodales eleifend nulla quam et ante. Fusce malesuada massa nec magna vulputate, eu tempor lorem pulvinar. Phasellus finibus est urna, vitae pellentesque dui finibus cursus.

						Suspendisse interdum bibendum augue, sed sollicitudin eros fringilla non. Cras nec dui ultricies, feugiat quam ac, vestibulum tellus. Nam varius dui id arcu auctor condimentum. Maecenas maximus dui nec sem accumsan hendrerit. Fusce convallis varius purus, sed aliquam felis feugiat quis. Ut ac odio sem. Phasellus nec venenatis quam. Aliquam erat volutpat. Vivamus at turpis aliquam, semper augue vel, euismod ante. Mauris sit amet iaculis lectus, in luctus quam. Sed tempor, arcu eget convallis venenatis, tellus ante porta risus, ac pharetra lacus velit et nunc. Cras et mollis erat. Sed eros nisl, fringilla sit amet neque sed, ultricies sollicitudin dolor. In non placerat leo. Pellentesque auctor porta suscipit. Praesent molestie iaculis nisi eu tincidunt.

						Quisque nec lacinia erat, ut sagittis lectus. Donec porttitor tellus id vehicula dictum. Nunc malesuada est dui, eu molestie nisl tincidunt ut. Cras a ante in mi commodo aliquam. Duis et tincidunt ante, quis lacinia enim. Praesent molestie lorem justo, ultrices congue massa auctor at. Integer pellentesque sapien id metus hendrerit maximus. Cras dapibus facilisis nisl, sed laoreet arcu congue et. Nulla vestibulum pellentesque mauris vitae consequat. Nam scelerisque, est non porttitor aliquet, nunc neque volutpat augue, non pharetra leo dui ac nisi.</p>
					</Descriptions.Item>
					<Descriptions.Item label="Participants" span={2}>
						<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vulputate vestibulum enim, at facilisis diam aliquet quis. Cras maximus dui euismod, mattis ex sed, fermentum magna. Fusce ex orci, ultricies id sagittis in, feugiat at purus. Fusce eu imperdiet lacus. Ut malesuada ligula id nisl finibus, a aliquam erat imperdiet. Aenean vulputate nibh neque, quis posuere turpis sollicitudin facilisis. Nulla purus purus, congue ut nunc sed, convallis hendrerit urna. Morbi dictum quam sed quam venenatis auctor. Praesent at augue elementum, pellentesque augue sed, auctor arcu. Sed rhoncus ut turpis et gravida. Integer consequat aliquet justo eget ullamcorper. Donec at augue ullamcorper, elementum massa a, euismod arcu. </p>
					</Descriptions.Item>
					<Descriptions.Item label="Additional info" span={2}>
						<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vulputate vestibulum enim, at facilisis diam aliquet quis. Cras maximus dui euismod, mattis ex sed, fermentum magna. Fusce ex orci, ultricies id sagittis in, feugiat at purus. Fusce eu imperdiet lacus. Ut malesuada ligula id nisl finibus, a aliquam erat imperdiet. Aenean vulputate nibh neque, quis posuere turpis sollicitudin facilisis. Nulla purus purus, congue ut nunc sed, convallis hendrerit urna. Morbi dictum quam sed quam venenatis auctor. Praesent at augue elementum, pellentesque augue sed, auctor arcu. Sed rhoncus ut turpis et gravida. Integer consequat aliquet justo eget ullamcorper. Donec at augue ullamcorper, elementum massa a, euismod arcu. </p>
					</Descriptions.Item>
					<Descriptions.Item label="Contact info" span={2}>
						<p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vulputate vestibulum enim, at facilisis diam aliquet quis. Cras maximus dui euismod, mattis ex sed, fermentum magna. Fusce ex orci, ultricies id sagittis in, feugiat at purus. Fusce eu imperdiet lacus. Ut malesuada ligula id nisl finibus, a aliquam erat imperdiet. Aenean vulputate nibh neque, quis posuere turpis sollicitudin facilisis. Nulla purus purus, congue ut nunc sed, convallis hendrerit urna. Morbi dictum quam sed quam venenatis auctor. Praesent at augue elementum, pellentesque augue sed, auctor arcu. Sed rhoncus ut turpis et gravida. Integer consequat aliquet justo eget ullamcorper. Donec at augue ullamcorper, elementum massa a, euismod arcu. </p>
					</Descriptions.Item>
					<Descriptions.Item label="Gallery" span={2}>
						<Carousel effect='fade' style={{height:'20vh', width:'40vw'}}>
							<div>
								<h3 style={contentStyle}>1</h3>
							</div>
							<div>
								<h3 style={contentStyle}>2</h3>
							</div>
							<div>
								<h3 style={contentStyle}>3</h3>
							</div>
							<div>
								<h3 style={contentStyle}>4</h3>
							</div>
						</Carousel>
					</Descriptions.Item>
				</Descriptions>
			</div>
    );
}
export default DemoDisplay;
