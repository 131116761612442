import React,{ useState, Component} from 'react';
import ReactDOM from 'react-dom';
import ExplorePage from '../pages/ExplorePage';
import { HashRouter as Router, Route } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import {Layout, Menu,Switch, Divider} from 'antd';
import {useLocation,Link} from 'react-router-dom';
import { withRouter } from 'react-router';
import SubMenu from 'antd/lib/menu/SubMenu';
import {DashboardOutlined, ContainerOutlined, EyeOutlined, BarsOutlined} from '@ant-design/icons';
const {Sider} = Layout;

class SiderComponent extends Component{
    state = {
        collapsed: false,
        theme: 'light'
    };
    onCollapse = collapsed =>{
        this.setState({collapsed});
    };

    render(){
        return(
            <Layout.Sider
                style={{minHeight: '100vh'}}
                width="auto"
            >

                <Layout style={{minHeight: '100vh'}}>

                    <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} style={{overflow: "auto"}} >

                    <Menu
                        theme="dark"
                        mode="inline"
                        style={{
                        height: '100%', borderRight: 0
                            }}
                            >

                        <SiderFunction />

                    </Menu>


                    </Sider>
                </Layout>
            </Layout.Sider>

  );


    }
}









function SiderFunction(){

        const location = useLocation();
        if(location.pathname === '/dashboard' || location.pathname === '/explore'){
            return(
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={["/dashboard"]}
                  style={{
                  	height: '100%', borderRight: 0
                  }}
                >


                	<Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
										<Link to="/dashboard">Dashboard</Link>
									</Menu.Item>

									<Menu.Item key="/explore" icon={<ContainerOutlined />}>
										<Link to="/explore">Explore</Link>
									</Menu.Item>
								</Menu>
            );
        }
        else if(location.pathname === '/digitaltwin' || location.pathname === '/potree' || location.pathname === '/pistepilvi' || location.pathname.includes('forge')){
            return(
                <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={["potree"]}
                        defaultOpenKeys={['potree']}
                        style={{
                        height: '100%', borderRight: 0
                            }}

                >


                        <Menu.Item key="potree" icon={<EyeOutlined />}>

                            <Link to="/potree">Oulu Minipilot</Link>

                </Menu.Item>
                <Menu.Item key="pistepilvi" icon={<EyeOutlined />}>
									<Link to="/pistepilvi">Pistepilvi</Link>
								</Menu.Item>
								<Menu.Item key="forge" icon={<EyeOutlined />}>
									<Link to="/forge">Forge</Link>
								</Menu.Item>
                {/* OLD FORGE
									<Menu.Item key="forge" icon={<EyeOutlined />}>
                            <Link to="/forge2leg">Forge</Link>
                </Menu.Item>
								<Menu.Item key="forgebim" icon={<EyeOutlined />}>
									<Link to="/forgebim">Forge BIM</Link>
								</Menu.Item>*/}
                </Menu>
            );
        }
				else if(location.pathname.includes('demo')){
					return(
						<Menu
							theme="dark"
							mode="inline"
							style={{
								height: '100%', borderRight: 0
							}}
						>
						<SubMenu key="/demomenu" title="Demos" icon={<BarsOutlined />}>
							<Menu
								theme="dark"
								mode="inline"
									style={{
										height: '100%', borderRight: 0
									}}
								>
									<Menu.Item key="/demo0">
										<Link to="/demo0">Demo #1</Link>
									</Menu.Item>
								</Menu>
							</SubMenu>
						</Menu>
					)
				}
        else{
            return(null);
        }


}



export default withRouter(SiderComponent);
