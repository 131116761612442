import React from 'react';
import Viewer from '../components/ForgeViewer';
const ReactViewer = () =>{

    return(



            <iframe src="http://majakka.westeurope.cloudapp.azure.com/forge/" width='100%' height='100%'></iframe>


    );

}
export default ReactViewer;
